//colors
$color--primary: #1cbbc6;
$color--red: #ff6f88;
$color--white: #fff;
$color--mint: rgba(28, 187, 198, 0.1);
$color--mint-100: rgba(28, 187, 198, 0.8);
$color--mint-200: #21cbcb;
$color--gray: rgba(58, 64, 79, 0.5);
$color--gray-100: #f6f6f6;
$color--gray-200: rgba(58, 64, 79, 0.8);
$color--gray-300: #aeb0b8;
$color--gray-400: #9da0a7;
$color--dark: #3a404f;

$color-text-primary: $color--dark;
$color-text-secondary: #616672;
$color-text-tetritary: #adb0b8;
$color-text-brand-1: #06646b;
$color-text-brand-2: #0e9aa4;
$color-text-negative-1: #712835;
$color-text-negative-2: #e56178;
$color-text-warning-1: #816042;
$color-text-positive-1: #2e615b;

$color-surface-primary: #fefefe;
$color-surface-secondary: #fafafc;
$color-surface-tetritary-1: #f5f5f7;
$color-surface-tetritary-2: #d8d9dc;
$color-surface-tetritary-3: #adb0b8;

$color-surface-brand-1: #effcfd;
$color-surface-brand-2: #e1f9fb;
$color-surface-brand-3: #d4f4f5;
$color-surface-brand-4: #b2ecf0;
$color-surface-brand-5: #80dee3;

$color-surface-positive: #c3f5ea;
$color-surface-warning: #ffefce;

$color-surface-negative-1: #fef5f7;
$color-surface-negative-2: #feecf0;
$color-surface-negative-3: #fba7b6;

$color-surface-invert: #3a404f;

$color-border-primary: $color--dark;
$color-border-secondary: #c2c5cc;
$color-border-tetritary-1: #d8d9dc;
$color-border-negative: #ff6f88;
$color-border-brand-2: #0e9aa4;

$color-icon-negative-2: #ff6f88;
$color-icon-brand-2: #0e9aa4;
$color-icon-brand-3: #32bac3;
$color-icon-invert: #fefefe;
$color-icon-secondary: #b0b3b9;
$color-icon-tetritary-1: #d5d7db;

//borders
$border-radius--sm: 6px;
$border-radius--md: 8px;
$border-radius--lg: 12px;

//box-shadows
$box-shadow--sm: 0 0 16px 0 rgba(27, 51, 109, 0.1);
$box-shadow-focus: 0 0 0 3px rgba(0, 157, 160, 0.25);

//mobile header
$mobile-header-height: 100px;
