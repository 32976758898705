$bgcolor: white;

.nav-tabs {
  border-bottom: none;
  flex-wrap: nowrap;

  li {
    margin: 0 4px;
    position: relative;
    &:has(.nav-link:hover) {
      &:after {
        display: none;
      }
    }
    &:has(.nav-link.active) {
      &:after {
        content: '';
        left: 50%;
        transform: translate(-50%);
        top: 44px;
        width: calc(100% - 44px);
        height: 1px;
        background: $color--primary;
        display: block;
        position: absolute;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:first-child {
      margin-left: 0;
      .nav-link {
        &.active {
          &:before {
            display: none;
          }
        }
      }
    }
    .nav-link {
      border: none;
      border-radius: 12px 12px 0 0;
      font-size: 14px;
      padding: 14px 24px 20px;
      position: relative;
      color: rgba(58, 64, 79, 0.8);
      white-space: nowrap;
      background-color: #efefef;
      transition:
        background-color 0.2s,
        transform 0.2s;

      &:not(.active) {
        transform: translateY(6px);

        &:hover,
        &:focus {
          background-color: #fbfbfb;
          transform: translateY(4px);
        }
      }

      &.active {
        position: relative;
        font-weight: 600;
        color: $color--dark;
        &:before,
        &:after {
          content: '';
          position: absolute;

          height: 10px;
          width: 20px;

          bottom: 0;
        }

        &:after {
          right: -20px;

          border-radius: 0 0 0 10px;
          -moz-border-radius: 0 0 0 10px;
          -webkit-border-radius: 0 0 0 10px;

          -webkit-box-shadow: -10px 0 0 0 #fff;
          box-shadow: -10px 0 0 0 #fff;
        }

        &:before {
          left: -20px;

          border-radius: 0 0 10px 0;
          -moz-border-radius: 0 0 10px 0;
          -webkit-border-radius: 0 0 10px 0;

          -webkit-box-shadow: 10px 0 0 0 #fff;
          box-shadow: 10px 0 0 0 #fff;
        }
      }
    }
  }
}

.nav-tabs .nav-link.active {
  background-color: $bgcolor;
}

.nav-tabs .nav-item:first-child {
  .nav-link.active::before {
    display: block !important;
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: $bgcolor;
  }
}

.tab-content {
  padding: 0;

  div.card {
    margin-top: 0;
  }
}
