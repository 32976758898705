.si-wrapper-top {
  .si-content-wrapper {
    border: none;
    padding: 16px;
    border-radius: $border-radius--lg;
  }

  .si-close-button {
    top: 4px;
    right: 6px;
    font-size: 28px;
    opacity: 0.8;
  }
}
