.badge {
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 400;

  &-success {
    color: $color-text-positive-1;
    background: $color-surface-positive;
  }

  &-warning {
    color: $color-text-warning-1;
    background: $color-surface-warning;
  }

  &-secondary {
    color: $color-text-primary;
    background: $color-surface-tetritary-2;
  }

  &-danger {
    color: $color-text-negative-2;
    background: $color-surface-negative-2;
  }

  &-number {
    display: grid;
    place-content: center;
    width: 22px;
    aspect-ratio: 1;
    border-radius: 50%;
    color: $color-text-negative-1;
    background: $color-surface-negative-3;
    box-shadow: 0 0 10px 0 $color-surface-negative-3;
  }
}
