.navbar {
  z-index: 3; // required to make the shadow overlap the below content (need is visible in mobile drawer overlay)
  border: 0;
  border-radius: 0;

  &.header-navbar {
    padding: 16px 0px;

    .container-fluid {
      background: #fefefe;
      padding: 8px;
      border-radius: 16px 0 0 16px;
    }
  }

  .nav-item {
    line-height: normal;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  }

  .navbar-brand {
    position: relative;
  }

  .nav-link {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .navbar-collapse {
    padding-bottom: 10px;
  }

  .navbar-nav {
    display: flex;
    gap: 4px;

    .dropdown-menu {
      position: absolute;
      border-radius: 8px;
    }
  }

  .navbar-toggle {
    background-color: transparent;
    border: none;

    .icon-bar {
      background-color: inherit;
      border: 1px solid;

      & + .icon-bar {
        margin-top: 4px;
      }
    }
  }

  .notification {
    position: absolute;
    top: -1px;
    right: 13px;
  }
}

@media (max-width: 767px) {
  //For responsive mega menu
  #navbar-mobile.navbar-collapse {
    .navbar-nav {
      margin: 0;
      flex-flow: row wrap;

      .dropdown-menu {
        position: absolute;
      }

      .nav-item {
        float: left;
      }
    }
  }
}

// Media queries for device support
// =========================================
@include media-breakpoint-down(md) {
  .header-navbar {
    .navbar-container {
      ul.navbar-nav {
        li {
          float: right !important;

          > a.nav-link {
            padding: 0.5rem 0.5rem;
          }
        }
      }
    }
  }
}

[dir='rtl'] {
  @include media-breakpoint-down(md) {
    .header-navbar {
      .navbar-container {
        ul.navbar-nav {
          li {
            float: left !important;
          }
        }
      }
    }
  }
}
