app-form label span.required::before,
app-add-form label span.required::before,
app-search-form label span.required::before {
  color: #ea0000;
  content: '*';
  left: 0;
  position: relative;
}

span.required::before {
  color: #ea0000;
  content: '*';
  left: 0;
  position: relative;
}

.form-group-legend {
  font-size: 0.75rem;
}

fieldset:disabled dynamic-ng-bootstrap-form .form-control:disabled {
  background-color: $color-surface-secondary;
}

fieldset:disabled {
  ::-webkit-input-placeholder {
    color: transparent !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: transparent !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent !important;
  }

  :-ms-input-placeholder {
    color: transparent !important;
  }
}

label {
  text-transform: none;
  margin-bottom: 4px;
  font-size: 13px;
}

dynamic-ng-bootstrap-datepicker {
  .input-group-append {
    button {
      &:after {
        content: '\e10a';
        font-family: 'Phosphor' !important;
        font-size: 20px;
        color: $color-text-primary;
      }

      img {
        display: none;
      }
    }
  }
}

fieldset:disabled dynamic-ng-bootstrap-form .d-disabled-none {
  display: none;
}

// icon for app-form and app-add-form components
// ft-home
.ft-farmBaseGroup:before,
.ft-organizationBaseGroup:before,
.ft-descriptionGroup:before,
.ft-addressGroup:before,
.ft-fertilizerCalculationInformationGroup:before,
.ft.ft-target.ft-observationBaseGroup:before {
  content: '\e965';
}

// ft-eye
.ft-fertilizerCalculationAuditInformationGroup:before,
.ft-fertilizersGroup:before,
.ft-visibilityGroup:before {
  content: '\e952';
}

// .ft-image
.ft.ft-target.ft-photosGroup:before,
.ft.ft-target.ft-observationPhotosGroup:before {
  content: '\e966';
}

// ft-file-text
.ft-documentAttachmentGroup:before {
  content: '\e958';
}

// ft-tag
.ft-fertilizersGroup:before,
.ft-farmDetailGroup:before {
  content: '\e9c0';
}

// ft-bell
.ft-weatherStationAlertConfigurationBaseGroup:before {
  content: '\e91a';
}

// ft-map
.ft-mapGroup:before,
.ft-groundMapGroup:before,
.ft.ft-target.ft-observationMapGroup:before {
  content: '\e976';
}

// ft-home
.ft-baseInfoGroup:before,
.ft-documentBaseGroup::before,
.ft-groundBaseInfoGroup:before {
  content: '\e965';
}

// ft-map-pin
.ft-groundLocationGroup:before,
.ft-weatherStationLocationGroup:before {
  content: '\e975';
}

// ft-message-square
.ft-observationRecommendationGroup:before {
  content: '\e97b';
}

// ft-feather
.ft-productShowGroup:before,
.ft-fieldCardCultivationGroup:before {
  content: '\e955';
}

//ft-image
.ft-photos:before {
  content: '\e966';
}

// ft-user
.ft-employeeBaseInformationGroup:before {
  content: '\e9d7';
}

// ft-bookmark
.ft-employeeEmploymentInformationGroup:before {
  content: '\e91d';
}

// ft-phone
.ft-contactGroup:before {
  content: '\e997';
}

.checkbox-required {
  color: $color--red;
  display: contents;
}

.input-group {
  &:has(input[ngbdatepicker]) {
    .input-group-append {
      button {
        width: 2.75rem;
        background: $color-surface-tetritary-1;
        border: none;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;

        i {
          color: $color-text-primary;
        }

        &:hover i {
          color: $color-text-brand-1;
        }
      }
    }
  }

  .form-control:disabled + .input-group-append button {
    background: $color-surface-secondary;
  }
}

.custom-switch {
  .custom-control-label {
    font-size: 14px;

    &::before,
    &::after {
      cursor: pointer;
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    &::before,
    &::after {
      top: 0.2rem;
    }
  }
}
