$modal-width: 400px;
$modal-start-right-position: -400px;

.modal.right {
  -webkit-animation: fade-in-right 0.3s 1;
  -moz-animation: fade-in-right 0.3s 1;
  -o-animation: fade-in-right 0.3s 1;
  animation: fade-in-right 0.3s 1;
}

@-webkit-keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    right: 0;
  }
}

@-moz-keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}

@-o-keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0px);
    right: 0;
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
    right: 0;
  }
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: $modal-width;
  height: 100%;
  min-height: 100vh;
}

.modal.right .modal-content {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}

.modal.right .modal-body {
  padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
  right: 0;
}

.modal-dialog {
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100vh;
  .btn-danger {
    background-color: $color--primary;
    border-color: $color--primary;
    &:active {
      background-color: $color--primary !important;
      border-color: $color--primary !important;
    }
    &:focus {
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }
  .btn-outline-secondary {
    color: $color--dark;
    border: 1px solid rgba(58, 64, 79, 0.7);
    font-weight: 500;
    &:hover {
      color: #fff;
      background: $color--dark;
    }
  }

  .close {
    &:focus {
      outline: none;
    }
  }
}
