.labelfocus {
  color: #000;
}
.form-group {
  margin-bottom: 24px;
  &--big {
    margin-bottom: 56px;

    .form-item:last-child {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
}

ng-select {
  &.ng-select {
    .ng-select-container {
      background: $color-surface-tetritary-1;
      border: 1px solid $color-surface-tetritary-1;
      border-radius: $border-radius--md;
      height: 44px;

      &:hover {
        box-shadow: none;
      }
      .ng-value-container {
        .ng-placeholder {
          color: $color--gray-300;
        }
      }
    }

    &.ng-select-single {
      .ng-select-container {
        height: 44px;

        .ng-value-container {
          .ng-input {
            top: 10px;
            padding-left: 8px;
          }
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        height: auto;
        min-height: 44px;

        .ng-value-container {
          .ng-value {
            padding: 2px 4px;
            border-radius: $border-radius--sm;
            word-break: break-word;
            white-space: normal;
          }
        }
      }
    }

    &.ng-select-disabled {
      & > .ng-select-container {
        background-color: $color-surface-secondary;
        border-color: $color-surface-secondary;

        &:hover {
          box-shadow: none;
        }
      }
    }

    &.ng-select-focused {
      .ng-select-container {
        background: $color-surface-primary;
        border-color: $color-border-primary;
      }

      &:not(.ng-select-opened) {
        .ng-select-container {
          border-color: $color-border-primary;
          box-shadow: none;
        }
      }
    }

    .ng-arrow-wrapper {
      width: 30px;
      padding-right: 10px;

      &:before {
        display: block;
        font-family: 'Phosphor';
        content: '\e136';
        font-size: 20px;
        color: $color-text-primary;
      }

      .ng-arrow {
        display: none;
      }
    }

    &.ng-select-opened .ng-arrow-wrapper:before {
      transform: rotate(180deg);
    }

    &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
      top: 10px;
      padding-left: 5px;
    }
  }

  &.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
  &.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
    border-radius: $border-radius--md;
  }
}

.form-control {
  border-radius: $border-radius--md;
  background-color: $color-surface-tetritary-1;
  border: 1px solid $color-surface-tetritary-1;
  min-height: 44px;
  padding: 10px 12px;
  &::placeholder {
    color: $color-text-tetritary;
  }

  &:focus {
    background-color: $color-surface-primary;
    border-color: $color-border-primary !important;
    box-shadow: none !important;
  }

  &:disabled,
  &[readonly] {
    background-color: $color-surface-secondary;
    border-color: $color-surface-secondary;
  }
}

select.form-control {
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 20px;
  padding-right: 30px;
}

.form-control-position {
  position: absolute;
  top: 2px;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
}
.has-icon-left {
  .form-control-position {
    right: auto;
    left: inherit;
  }
  .form-control {
    padding-right: 0.85rem;
    padding-left: 2.95rem;
  }
}
.has-icon-right {
  .form-control {
    padding-right: 2.5rem;
  }
}
.form-section {
  color: theme-color('dark');
  line-height: 3rem;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-bottom: 20px;
  border-bottom: 1px solid theme-color('dark');

  i {
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.form-actions {
  border-top: 1px solid darken($body-bg, 10%);
  padding: 20px 0;
  margin-top: 20px;

  &.filled {
    background-color: $body-bg;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  &.top {
    border-top: 0;
    border-bottom: 1px solid darken($body-bg, 10%);
    margin-top: 0;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    .buttons-group {
      float: left !important;
      margin-bottom: 10px;
    }
  }
}

.input-group-append {
  margin-left: 0;
  .btn {
    height: 44px;
  }
}

/* 
TODO:M: To check with latest bootstrap version and remove it. 
Bootstrap Enhance */
// Custom checkbox
.custom-control {
  display: block;
  &.inline {
    display: inline;
  }
}
.custom-checkbox input:disabled:checked ~ .custom-control-indicator {
  opacity: 0.5;
}
.custom-checkbox input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
}
.custom-control-input > input:focus ~ .custom-control-indicator {
  padding: 0.51rem;
}
.custom-control-indicator {
  width: 1.1rem;
  height: 1.075rem;
  line-height: 1.05rem;
  top: 0.085rem;
  + .custom-control-description {
    margin-left: 1rem;
  }
}

.input-group-addon {
  background-color: #eceff1;
}

.form-control-xl {
  padding: 0.8rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-xl:not([size]):not([multiple]) {
  height: calc(3.25rem + 2px);
}

.input-group-addon input[type='radio'],
.input-group-addon input[type='checkbox'] {
  margin-top: -4px;
  margin-left: -1rem;
}

.form-control-xl,
.input-group-xl > .form-control,
.input-group-xl > .input-group-addon,
.input-group-xl > .input-group-btn > .btn {
  padding: 0.8rem 1rem;
}

.ng-dynamic-forms-group > label {
  h5 {
    &:before {
      display: none;
    }
  }
  i {
    display: none;
  }
}

[dir='rtl'] {
  form {
    .form-control-position {
      left: 0;
      right: auto;
    }
    .has-icon-left {
      .form-control-position {
        left: auto;
        right: inherit;
      }
      .form-control {
        padding-left: 0.85rem;
        padding-right: 2.95rem;
      }
    }
    .has-icon-right {
      .form-control {
        padding-left: 2.5rem;
        padding-right: auto;
      }
    }

    .form-actions {
      &.right {
        text-align: left;
      }

      @include media-breakpoint-down(sm) {
        .buttons-group {
          float: right !important;
        }
      }
    }

    &.form-horizontal {
      .form-group {
        @include media-breakpoint-up(sm) {
          .label-control {
            text-align: left;
          }
        }
      }
    }

    &.row-separator {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: auto;
        }
      }
    }

    &.form-bordered {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: auto;
          border-right: 1px solid darken($body-bg, 3%);
        }
      }
    }

    &.striped-rows {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: auto;
        }
      }
    }

    &.striped-labels {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: auto;
        }
      }
    }
  }

  .custom-control-indicator {
    + .custom-control-description {
      margin-right: 1rem;
      margin-left: auto;
    }
  }

  .input-group-addon input[type='radio'],
  .input-group-addon input[type='checkbox'] {
    margin-right: -1rem;
    margin-left: auto;
  }
}

.invalid-feedback {
  font-size: 12px;
  color: $color-text-negative-2;
  line-height: 1.3;
}

.form-control.ng-touched.ng-invalid,
ng-select.ng-touched.ng-invalid .ng-select-container {
  border-color: $color-border-negative;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 12px;
  background-image: none;
}
