//Variables
$sideber-border: $color-border-tetritary-1;
$sideber-width: 250px;

.email-application {
  .email-app-sidebar {
    width: $sideber-width;
    padding-right: 16px;

    .email-app-sidebar-content {
      position: relative;
      height: 100%;
    }
  }

  .email-app-content-area {
    background-color: $color-surface-primary;
    border-radius: $border-radius--lg;
    overflow: hidden;
    display: grid;
    grid-template-columns: 360px 1fr;
  }

  .content-overlay.show {
    background: rgba(0, 0, 0, 0.9);
  }

  .email-app-content {
    margin-top: 0px;
    padding: 0px;
    min-height: 100%;
  }

  .email-app-list-mails {
    position: relative;
    padding: 20px 0;
    height: calc(100vh - 175px);
  }

  .email-app-mail-content {
    position: relative;
    padding: 24px;
    min-height: calc(100vh - 115px);
  }

  .email-app-mail-content-detail {
    position: relative;
    max-height: calc(100vh - 155px);
    overflow-y: auto;
  }

  .email-app-header {
    border-bottom: 1px solid $color-border-tetritary-1;
  }

  .hide-email-content {
    transition: all 0.5s ease-in-out;
    transform: translate3d(-110%, 0, 0);
  }

  .subtitle {
    font-size: 16px;
    font-weight: 500;
  }

  .email-app-menu {
    .form-group-compose {
      border-bottom: 1px solid $sideber-border;
    }
  }

  .message-list {
    height: 100%;
    padding: 10px 20px;
    overflow-y: scroll;

    .message-intro {
      display: block;
      padding: 12px;
      position: relative;
      border-radius: $border-radius--md;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: $border-radius--md;
        box-shadow: 0 0 10px 0 $color-surface-tetritary-2;
        opacity: 0;
        transition: opacity 0.15s;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      & + .message-intro {
        &:after {
          content: '';
          position: absolute;
          top: -1px;
          left: 5px;
          right: 5px;
          height: 1px;
          background-color: $color-border-tetritary-1;
        }
      }

      &.message-selected {
        border: 1px solid $color-border-tetritary-1;
        background: $color-surface-tetritary-1;
      }

      &-sender {
        color: $color-text-secondary;
      }

      &-date {
        font-size: 12px;
        color: $color-text-tetritary;
      }
    }

    .message-unread {
      .message-intro-sender,
      .message-intro-header {
        font-weight: 650;
      }
    }
  }

  .message-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .message-categories {
    display: flex;
    gap: 12px;
    padding: 0 28px 0 20px;

    &-item {
      flex: 1;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        margin-bottom: 12px;
        font-size: 14px;
        border-radius: $border-radius--md;
        background-color: $color-surface-tetritary-1;
        transition: background-color 0.15s;

        &:hover {
          background-color: $color-surface-tetritary-2;
        }

        &.active {
          background-color: $color-text-primary;
          color: $color-surface-primary;
        }
      }
    }
  }

  .email-app-content-wrapper {
    overflow-y: scroll;
    height: calc(100% - 30px);

    .email-app-options,
    .email-app-title {
      border-bottom: 1px solid $sideber-border;
    }
  }

  .chat-fixed-search {
    // position: fixed;
    z-index: 999;
    background: $white;
    padding: 2rem 1rem;

    fieldset {
      border-bottom: 1px solid $sideber-border;
    }
  }
}

@include media-breakpoint-down(sm) {
  .email-app-mail-content {
    position: absolute !important;
    top: 0 !important;
    left: 7px !important;
    width: 98% !important;
    height: 100%;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    max-height: calc(100vh - 10px);
  }
}

@include media-breakpoint-down(md) {
  .email-application {
    .email-app-sidebar {
      left: 0;
    }
  }
}

[dir='rtl'] {
  .email-application {
    .email-app-sidebar {
      border-left: 1px solid $sideber-border;
      border-right: 0px;
    }

    .email-app-mail-content {
      float: right;
    }
  }

  @include media-breakpoint-up(xl) {
    .email-app-content {
      margin-right: $sideber-width;
      margin-left: 0px;
    }
  }

  @include media-breakpoint-down(sm) {
    .email-app-mail-content {
      right: 15px !important;
      left: auto;
    }
  }

  @include media-breakpoint-down(md) {
    .email-application {
      .email-app-sidebar {
        right: 0;
        left: auto;
      }
    }
  }
}
