// Modals
// https://www.google.com/design/spec/components/dialogs.html#dialogs-specs

.modal-content {
  border-radius: $border-radius--md;
  border: none;
  box-shadow: $box-shadow--sm;

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .close {
    font-family: 'Phosphor';
    content: '\e9e6';
    font-size: 26px;
    opacity: 1;
  }

  .modal-footer {
    .btn {
      margin: 0;
    }

    .btn + .btn {
      margin-left: 12px;
    }
  }
}
