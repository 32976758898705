.tooltip {
  &.show {
    opacity: 1;
  }

  &-inner {
    padding: 12px;
    color: $color--white;
    background-color: $color-surface-invert;
    border-radius: 8px;
  }
}

.bs-tooltip-top .arrow::before {
  border-top-color: $color-surface-invert;
}
