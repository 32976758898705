.btn {
  height: 44px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: $border-radius--md;
  border: 1px solid transparent;
  font-weight: 500;
  letter-spacing: 0.056px;

  i {
    font-size: 20px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: $box-shadow-focus;
  }

  &.disabled,
  &:disabled {
    color: $color-text-tetritary;
    background: transparent;
    border-color: $color-border-tetritary-1;
    box-shadow: none;
    pointer-events: none;
    opacity: 1;

    i {
      color: $color-icon-secondary;
    }
  }

  &-primary {
    background: linear-gradient(180deg, #4dc9d2 -44.41%, #06646b 99.56%);
    border-color: #32a5ad;
    color: $color--white !important;
    box-shadow: 0 0 10px 0 $color-surface-brand-4;

    &:hover {
      background: linear-gradient(180deg, #118088 0.44%, #044e57 99.56%);
      border-color: #118088;
    }

    &:focus-visible {
      background: linear-gradient(180deg, #3a404f 2.73%, #118088 157.65%);
      box-shadow: 0 0 10px 0 $color-surface-brand-5 !important;
      border-color: $color-surface-primary;
    }

    &:active,
    &.active {
      background: linear-gradient(180deg, #3a404f 2.73%, #118088 157.65%);
      box-shadow: 0 0 10px 0 $color-surface-brand-2 !important;
    }

    i {
      color: $color--white;
    }

    &.disabled,
    &:disabled {
      color: $color--gray-400;
      background: $color--gray-400;
      border-color: $color--gray-400;
      box-shadow: none;

      i {
        color: $color-icon-invert;
      }
    }
  }

  &-primary-outline {
    background: $color-surface-primary;
    border-color: $color-border-brand-2;
    color: $color-text-primary;

    &:hover {
      background: $color-surface-brand-1;
    }

    &.active {
      background: $color-surface-brand-3;
    }

    &:focus-visible {
      background: $color-surface-brand-1;
      box-shadow: 0 0 10px 0 $color-surface-brand-4;
    }

    i {
      color: $color-icon-brand-2;
    }
  }

  &-secondary {
    background: $color-surface-primary;
    border-color: $color-border-secondary;
    color: $color-text-primary;

    &:hover {
      background: $color-surface-tetritary-1;
      border-color: $color-border-secondary;
      color: $color-text-primary;
    }

    &:focus-visible {
      background: $color-surface-secondary;
      box-shadow: 0 0 10px 0 $color-surface-tetritary-2 !important;
    }

    &:not(:disabled):not(.disabled):active {
      background: $color-surface-primary;
      border-color: $color-border-secondary;
      color: $color-text-primary;
    }

    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
    }

    i {
      color: $color-icon-brand-2;
    }
  }

  &-negative {
    background: $color-surface-negative-1;
    border-color: $color-border-negative;
    color: $color-text-primary;

    &:hover {
      background: $color-surface-negative-2;
    }

    &:focus-visible {
      background: $color-surface-negative-2;
      box-shadow: 0 0 10px 0 $color-surface-negative-3 !important;
    }

    i {
      color: $color-icon-negative-2;
    }
  }

  &-action {
    background: $color-surface-primary !important;
  }

  &-link {
    color: $color-text-brand-2;
    text-underline-offset: 6px;
    border-radius: 0;
    padding: 0;
    height: auto;

    &:hover {
      color: $color-text-brand-2;
      border-bottom: 1px solid;
      text-decoration: none;
    }

    &:focus-visible {
      color: $color-text-brand-1;
      border-bottom: 1px solid;
      text-decoration: none;
    }

    &:active {
      color: $color-text-brand-1;
      border-color: transparent;
    }

    &.disabled,
    &:disabled {
      border-color: transparent;
    }
  }

  &-icon {
    aspect-ratio: 1;
    padding: 4px;

    &.disabled,
    &:disabled {
      border-color: transparent;
    }
  }

  &-sm {
    height: 24px;
  }

  &-round {
    border-radius: 50%;
  }
}

.btn .btn.btn-tab {
  height: 56px;
  font-weight: 600;
  margin-bottom: 0;
  border-radius: 12px 12px 0px 0px;
  box-shadow: none;
  background: none;
  &.active {
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(58, 64, 79, 0.12);
    &:after {
      content: '';
      position: absolute;
      height: 10px;
      width: 20px;
      bottom: 0;
    }
  }
}
