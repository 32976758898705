body {
  min-width: 1200px;
}

.headline {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
}

.headline-underline {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid $color--dark;
  margin-bottom: 24px;
}

h5 {
  font-weight: 650;
  font-size: 20px;
}

.font-sm {
  font-size: 13px;
}

b,
strong {
  font-weight: 600;
}

dt {
  font-weight: 600;
}

dd {
  line-height: 1.6;
}

/* workaround https://github.com/udos86/ng-dynamic-forms/issues/915 */
form.ng-dynamic-form.ng-valid input.ng-touched.ng-invalid,
form.ng-dynamic-form.ng-untouched input.ng-touched.ng-invalid {
  border: 1px solid #a6a9ae;
}

lightbox,
.lightbox {
  z-index: 110;
}

.menu-collapsed {
  .logo-farmportal {
    display: none;
  }
}

.logo-farmportal {
  height: 30px;
}

.main-spinner > app-loading-spinner > div.backdrop {
  position: fixed !important;
}

.no-header-group {
  > div:first-child > label {
    display: none;
  }

  hr {
    display: none;
  }

  .no-label {
    margin-top: 31px;

    label {
      display: none;
    }
  }
}
.card {
  &-header {
    h2,
    h5 {
      color: #444034;
      font-weight: 400;
    }
  }
}
label {
  h2,
  h5 {
    color: #444034;
    font-weight: 400;
  }
}

.text-balance {
  text-wrap: balance;
}

// positions
.right-0 {
  right: 0;
}

// sidebar
.app-sidebar {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

  @include media-breakpoint-up(xl) {
    box-shadow: none;
  }
}

@include media-breakpoint-down(md) {
  .logo-farmportal {
    position: relative;
  }
}

.nav-toggle,
.nav-close {
  right: auto;
  left: 1rem;
}

.nav-close {
  font-size: 1.5rem;
  top: 50%;
  transform: translate(0, -50%);
}

// maps
.si-content {
  max-height: 100% !important;
  min-width: 300px !important;
  width: 350px;
  @include media-breakpoint-down(md) {
    min-width: 150px !important;
  }
  .si-modal {
    &-data {
      border: 1px solid #e4e7eb;
      border-radius: 3px;
      padding: 0.25rem;
      text-align: center;
      color: #a2a2a2;
      font-weight: 100;
      align-self: center;
    }
    .farm_icon {
      margin-right: 0.5rem;
    }
  }
}
.si-pointer-bg-top {
  top: -2px !important;
}

.progress-bar {
  background-color: #37aa32 !important;
}

.provider-status-ok {
  color: #155724;
  font-weight: bold;
}

.provider-status-disconnected {
  font-weight: bold;
  color: #721c24;
}

.page-title {
  font-weight: 700;
  margin-bottom: 0 !important;
}

.thumbnail {
  width: 100px;
  border-radius: 8px;

  &-sm {
    width: 96px;
    height: 72px;
    object-fit: cover;
  }
}

.agm-map-container-inner {
  border-radius: $border-radius--md;
}

.color {
  &-primary {
    color: $color--primary;
  }
  &-danger {
    color: $color--red;
  }
  &-text-primary {
    color: $color-text-primary;
  }
  &-text-secondary {
    color: $color-text-secondary;
  }
  &-text-tetritary {
    color: $color-text-tetritary;
  }
}

img {
  display: block;
  max-width: 100%;
}

.card-shadow {
  padding: 16px;
  border-radius: 10px;
  box-shadow: $box-shadow--sm;
  background-color: $color--white;
}

.card-bg-primary {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid $color-surface-brand-3;
  background: $color-surface-brand-1;
  box-shadow: 0 0 10px 0 $color-surface-brand-3;
}

.icon-size {
  &-16 {
    font-size: 16px !important;
  }
  &-20 {
    font-size: 20px !important;
  }
  &-22 {
    font-size: 22px !important;
  }
  &-24 {
    font-size: 24px !important;
  }
}

.alert {
  padding: 12px;
  border-radius: $border-radius--md;
  border: none;
  color: $color-text-primary !important;

  i {
    font-size: 16px;
  }

  &-info {
    background-color: $color-surface-brand-2 !important;
    color: $color-text-brand-1 !important;
  }

  &-success {
    background-color: $color-surface-positive !important;
  }

  &-warning {
    background-color: $color-surface-warning !important;
  }
}

.gap {
  @include gap-generator;
}

.text-truncate-2 {
  @include text-truncate(2);
}

.text-truncate-3 {
  @include text-truncate(3);
}

// global custom scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 12px;
  &:hover {
    background-color: #6d6d6d;
  }
}

body {
  &::-webkit-scrollbar-thumb {
    background-color: $color-surface-tetritary-3;
  }
}

// fix echart & bootstrap grid
[class^='col'] {
  min-width: 0;
}

.light-divider {
  display: block;
  width: 100%;
  margin: 12px 0px;
  border-top: 1px solid $color-surface-tetritary-2;
}
