.dropdown {
  .dropdown-toggle {
    &::after {
      font-family: 'Phosphor';
      content: '\e136';
      border: none;
      transform: rotate(0);
      transition: transform 0.3s;
      font-size: 20px;
    }
  }
  &.show {
    .dropdown-toggle {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

ng-dropdown-panel.ng-dropdown-panel {
  box-shadow: 0 0 10px 0 $color-surface-tetritary-2;
  border-radius: 8px;
  border: none;
  overflow: hidden;

  &.ng-select-bottom {
    transform: translateY(8px);
  }

  &.ng-select-top {
    transform: translateY(-8px);
  }

  .ng-option {
    color: $color-text-primary;

    &.ng-option-selected {
      font-weight: bold;
      background: none !important;
      position: relative;
    }

    &.ng-option-marked {
      background: $color-surface-tetritary-1 !important;
    }
  }

  &.ng-select-multiple {
    .ng-option-selected {
      &:after {
        content: '\e182';
        font-family: 'Phosphor' !important;
        color: $color--primary;
        font-size: 16px;
        position: absolute;
        right: 10px;
        top: 8px;
      }
    }
  }
}
