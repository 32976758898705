@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Theme Colors
$theme-colors: (
  primary: #009da0,
  success: #0cc27e,
  info: #1cbcd8,
  warning: #ff8d60,
  danger: #ff586b,
  secondary: #868e96,
  light: #f8f9fa,
  dark: #343a40
);

// Spacing

$spacer: 1.5rem;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 3
  )
) !default;

// Body
$body-bg: #f5f7fa;

// Fonts
$font-family-sans-serif: 'Inter', sans-serif;
$font-family-content: 'Inter', sans-serif;

$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$font-size-root: 15px;
